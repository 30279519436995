import http from "@/utils/services/http"

export const listGroups = (limit, page, filtros, exportar = false) => {
    let url = `/api/project/member-group?page=` + page + '&limit=' + limit + filtros
    let config = {}
    if (exportar){
        config.responseType = 'blob'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findGroup = (group) => {
    let url = `/api/project/member-group/${group}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newGroup = (data) => {
    let url = `/api/project/member-group`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateGroup = (group, method = 'PUT') => {
    let url = `/api/project/member-group/${group.id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, group)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteGroup = (group) => {
    let url = `/api/project/member-group/${group}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
