import {listGroups as list} from '@/domain/project/services/memberGroup'
import window from './window/window.js'

export default {
  pageTitle: 'Grupos de Membros de Projetos',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'name',
      sortable: true,
      parser: function (props) {
        return props.name
      }
    },
    {
      name: 'descricao',
      required: true,
      label: 'Descrição',
      align: 'left',
      field: 'description',
      sortable: true,
      parser: function (props) {
        return props.description
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: list,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    }
  }
}
