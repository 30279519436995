<template>
  <div class="config-app">
    <h2 class="app-title">Projetos</h2>
    <p class="subtitle">Permite você habilitar e configurar as preferências do módulo de projetos.</p>

    <app-tab :active="active" :menu="menu" @changeTab="(item) => active = item.name">
      <div v-if="active === 'basico'" class="app-input-container">
      </div>
      <list-type v-if="active === 'tipo'" class="app-input-container" />
      <list-member-group v-if="active === 'memberGroups'" class="app-input-container" />
      <list-knowledge-group v-if="active === 'knowledgeGroups'" class="app-input-container" />
      <list-queues v-if="active === 'fila'" class="app-input-container" />
    </app-tab>
  </div>
</template>

<script>
import AppMixin from './appMixin'
import ListType from "@/components/cadastro/components/tiposprojetos/List"
import ListMemberGroup from "@/components/cadastro/components/gruposmebrosprojetos/List"
import ListKnowledgeGroup from "@/components/cadastro/components/gruposknowledge/List"
import ListQueues from "components/cadastro/components/taskqueues/List"
// import {USelect, UCheckbox} from "uloc-vue"

export default {
  name: "ConfigTarefas",
  inject: ['config'],
  components: {
    ListQueues,
    ListType,
    ListMemberGroup,
    ListKnowledgeGroup
    // USelect,
    // UCheckbox
  },
  mixins: [AppMixin],
  data() {
    return {
      active: 'basico'
    }
  },
  computed: {
    listConfigName () {
      return 'project'
    },
    listConfigVal (){
      return this.config.config[this.listConfigName] || {value: 1}
    },
    menu() {
      return [
        {
          name: 'basico',
          label: 'Básico'
        },
        {
          name: 'tipo',
          label: 'Tipos de Projetos'
        },
        {
          name: 'memberGroups',
          label: 'Grupos de Membros de Projetos'
        },
        {
          name: 'knowledgeGroups',
          label: 'Grupos de Base de Conhecimento'
        },
        {
          name: 'fila',
          label: 'Fila de Tarefas'
        }
      ]
    }
  }
}
</script>
