import http from "@/utils/services/http"

export const listQueue = (limit, page, filtros, exportar = false) => {
    let url = '/api/tasks-queue?page=' + page + '&limit=' + limit + filtros
    let config = {}
    if (exportar){
        config.responseType = 'blob'
    }
    return http.get(url, config)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const findQueue = (id) => {
    let url = `/api/tasks-queue/${id}`
    return http.get(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const newQueue = (data) => {
    let url = `/api/tasks-queue`
    return http.post(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const updateQueue = (id, data, method = 'PUT') => {
    let url = `/api/tasks-queue/${id}`
    let _method = http.put
    if (method === 'PATCH') {
        _method = http.patch
    }
    return _method(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}

export const deleteQueue = (id) => {
    let url = `/api/tasks-queue/${id}`
    return http.delete(url)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
